<script>
export default {
  name: "StepTwo",
  inject: ["main"],
  data() {
    return {};
  },
  created() {
    if (this.main.radios.length === 1) {
      this.handlerNext();
    }
  },
  methods: {
    handlerNext() {
      if (this.main.select === "question") {
        this.main.active = this.main.questionNum;
      } else {
        this.main.next();
      }
    },
  },
  render() {
    return (
      <div class="steps__item">
        <el-form props={{ showMessage: false }}>
          <el-form-item>
            <h2>ID：{this.main.account}</h2>
          </el-form-item>
          <el-form-item>
            <el-radio-group v-model={this.main.select}>
              {this.main.radios.map((el, index) => {
                return (
                  <el-radio key={index} label={el.value}>
                    {el.label}
                  </el-radio>
                );
              })}
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button
              type={this.main.select ? "primary" : "info"}
              onClick={() => {
                if (this.main.select) {
                  this.handlerNext();
                }
              }}
            >
              <i class="el-icon--step-next" />
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    );
  },
};
</script>
