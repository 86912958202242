<script>
import {
  sendValidateCode,
  checkValidateCode,
} from "@/api/newVersion/loginAndRegister";
export default {
  inject: ["main"],
  data() {
    return {
      verCode: "",
      keysMap: {
        email: "secureEmail",
        phone: "securePhone",
      },
      btnTex: "",
      timeOut: false,
      time: 60,
      timeInter: null,
    };
  },
  created() {
    this.btnTex = this.$t("getverchatCode");
  },
  methods: {
    // 点击发送验证码
    async handlerGetVerCode() {
      if (!this.timeOut) {
        this.handlerTiming();
        const params = {};
        const account = this.$store.state.accountStore.secureInfo[
          this.keysMap[this.main.select]
        ];
        if (this.main.select === "phone") {
          params.phone = account.slice(
            account.indexOf("-") + 1,
            account.length
          );
          params.countryCode = account.slice(0, account.indexOf("-"));
        } else {
          params[this.main.select] = account;
        }
        await sendValidateCode(params);
      }
    },
    // 开始倒计时
    handlerTiming() {
      this.timeOut = true;
      this.timeInter = setInterval(() => {
        if (this.time > 0) {
          this.time--;
          this.btnTex = this.time + "s";
        } else {
          this.resetTimer();
        }
      }, 1000);
    },
    // 重置倒计时
    resetTimer() {
      // 重置倒计时数据
      this.time = 60;
      // 可以点击下一次发送验证码
      this.timeOut = false;
      // 重置按钮显示
      this.btnTex = this.$t("getverchatCode");
      // 重置定时器
      window.clearInterval(this.timeInter);
    },
    // 提交验证码
    async _handlerCheckVerCode() {
      const params = {
        code: this.verCode,
      };
      const account = this.$store.state.accountStore.secureInfo[
        this.keysMap[this.main.select]
      ];
      if (this.main.select === "phone") {
        params.phone = account.slice(account.indexOf("-") + 1, account.length);
        params.countryCode = account.slice(0, account.indexOf("-"));
      } else {
        params[this.main.select] = account;
      }
      const result = await checkValidateCode(params);
      if (result && result.code === 200) {
        this.resetTimer();
        this.main.handlerCatchData(this.verCode, this.main.select);
        this.main.next();
      } else {
        this.$message({
          type: "error",
          message: result.message || result.data.message,
        });
      }
    },
  },
  render() {
    return (
      <div class="steps__item forget_item">
        <el-form props={{ showMessage: false }}>
          <el-form-item>
            <h2>ID：{this.main.account}</h2>
          </el-form-item>
          <el-form-item>
            <span>
              {this.main._handlerHiddlenAccount(
                this.$store.state.accountStore.secureInfo[
                  this.keysMap[this.main.select]
                ],
                this.main.select
              )}
            </span>
          </el-form-item>
          <el-form-item>
            <el-input v-model={this.verCode}>
              <i slot="prepend" class="el-icon--dun" />
              <span slot="append" onClick={this.handlerGetVerCode}>
                {this.btnTex}
              </span>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type={this.verCode ? "primary" : "info"}
              onClick={() => {
                if (this.verCode) {
                  this._handlerCheckVerCode();
                }
              }}
            >
              <i class="el-icon--step-next" />
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    );
  },
};
</script>
<style lang="stylus">
.forget_item{
  .el-input-group__append{
    background #3c6 !important;
    color: #ffffff !important;
    padding 0 !important;
    span{
      display: flex;
      width: 111px;
      height: 38px;
      cursor pointer;
      align-items center;
      justify-content center;
    }
  }
}
</style>
