var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "steps" }, [
    _c("div", { staticClass: "steps__header" }, [
      _c("span", [_vm._v(_vm._s(_vm.$t("forgetPwd")))]),
      !_vm.loading
        ? _c("img", {
            staticClass: "servicer",
            attrs: {
              src:
                "https://image.melinked.com/customer_group_head.png?imageView2",
              alt: ""
            },
            on: {
              click: function($event) {
                _vm.loading ? "" : _vm.customerService()
              }
            }
          })
        : _c("div", { staticClass: "loading-box" }, [
            _c("i", { staticClass: "el-icon-loading" })
          ])
    ]),
    _c(
      "div",
      { staticClass: "steps__content" },
      [
        _c("div", { staticClass: "steps__content--back" }, [
          _vm.active && _vm.active != 4 && _vm.catchAccount.codeType != 3
            ? _c("i", {
                staticClass: "el-icon--prev blackBtn",
                on: { click: _vm.handlerPre }
              })
            : _vm._e()
        ]),
        _c(`step-${_vm.steps[_vm.active]}`, { tag: "component" })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }