<script>
import { checkAnswers } from "@/api/rest";
export default {
  inject: ["main"],
  data() {
    return {
      formData: {
        answers: []
      },
      rules: {},
      through: false
    };
  },
  created() {
    this.handlerGetQuestions();
  },
  methods: {
    // 验证安全问题是否填写完成
    verifyAnswer() {
      this.through = this.formData.answers.every(e=>{
        return e.answer !== ""
      })
    },
    // 获取设置的安全问题
    handlerGetQuestions() {
      this.formData.answers = this.$store.state.accountStore.secureInfo.secureQuestionAnswers.map(el => {
        return { ...el };
      });
    },
    // 点击提交答案
    handlerSubmitForm() {
      this.$refs.formData.validate(async valid => {
        if (valid) {
          const answer = this.formData.answers.map(el => {
            return { questionId: el.questionId, answer: el.answer };
          });
          const _check = await checkAnswers({
            providerId: this.$store.state.accountStore.secureInfo.providerId,
            secureQuestionAnswers: answer
          });
          if (_check && _check.data.data) {
            this.main.catchAccount = {
              codeType: 3,
              secureQuestionAnswers: answer
            };
            this.main.active = 3;
          } else {
            this.$message({
              type: "error",
              message: this.$t("answerError")
            });
          }
        } else {
          console.log("error");
        }
      });
    }
  },
  render() {
    return (
      <div class="steps__item">
        <el-form
          props={{ model: this.formData, rules: this.rules, showMessage: false }}
          ref="formData"
        >
          <el-form-item>
            <h2>ID：{this.main.account}</h2>
          </el-form-item>
          {this.formData.answers.map((el, index) => {
            return (
              <el-form-item
                prop={`answers.${index}.answer`}
                rules={{
                  required: true,
                  trigger: "blur",
                  message: this.$t("answer")
                }}
                label={el.question}
                key={`answer-${index}`}
              >
                <el-input v-model={el.answer} onInput={this.verifyAnswer}/>
              </el-form-item>
            );
          })}
          <el-form-item>
            <el-button 
              type={this.through ? "primary" : "info"}
              onClick={ () => {
                if( this.through ){
                  this.handlerSubmitForm()
                }
              }}
            >
              <i class="el-icon--step-next" />
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    );
  }
};
</script>
<style lang="stylus">
  .steps__item .el-form-item__label{
    text-align: left !important;
  }
</style>
