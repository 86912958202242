<script>
export default {
  data() {
    return {};
  },
  render() {
    return (
      <div class="steps__item">
        <div class="steps__item--success">
          <i class="el-icon--success"> </i>
          <span
            onClick={() => {
              this.$store.commit("setLoginAndRegisterDialog", true);
            }}
          >
            {this.$t("login")}
          </span>
        </div>
      </div>
    );
  }
};
</script>
