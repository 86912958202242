<script>
import { getSecureInfo } from '@/api/rest';
export default {
  name: "StepI0",
  inject: ["main"],
  data() {
    return {
      accountFrom: {
        account: ""
      },
      // 判断密码是否有问题
      haveAccount: false
    };
  },
  watch: {
    "main.radios.length": {
      handler(val) {
        this.haveAccount = val ? true : false;
      },
      deep: true
    }
  },
  created() {
    this.accountFrom.account = "";
    this.main.radios = [];
  },
  methods: {
    async _checkAccount(rule, value, callback) {
      if (!this.accountFrom.account) {
        this.haveAccount = false;
        return callback(new Error(this.$t("accountEmpty")));
      } else {
        let _checkName = await getSecureInfo(this.accountFrom.account);
        if (_checkName.code !== 200) {
          this.haveAccount = false;
          return callback(
            new Error(
              `ID：${this.accountFrom.account}`
            )
          );
        } else {
          const accountInfo = _checkName.data.data;
          // 验证过后将获取的账号安全信息存入状态管理器
          this.$store.commit("setSecureInfo", accountInfo);
          // 重置一下忘记密码安全信息模块的数据
          this.main.loadingData(this.$store.state.accountStore.secureInfo);
          this.haveAccount = (accountInfo.hasSecureQuestion || accountInfo.secureEmail || accountInfo.securePhone) ? true : false;
          this.haveAccount
            ? callback()
            : callback(
                new Error(
                  `ID：${this.accountFrom.account} ${this.$t(
                    "backPwdErrorTip"
                  )}`
                )
              );
        }
      }
    }
  },
  render() {
    return (
      <div class="steps__item">
        <el-form 
           props={{ model: this.accountFrom, showMessage: false }}
        >
          <el-form-item
            prop="account"
            rules={{ validator: this._checkAccount, trigger: "change" }}
          >
            <el-input
              prefix-icon="el-icon-user"
              placeholder="ID"
              v-model={this.accountFrom.account}
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type={this.haveAccount ? "primary" : "info"}
              onClick={ () => {
                if (this.haveAccount) {
                  this.main.account = this.accountFrom.account;
                  this.main.next();
                }
              }}
            >
              <i class="el-icon--step-next" />
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    );
  }
};
</script>
