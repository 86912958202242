<script>
import { setNewPwd } from "@/api/rest";
export default {
  inject: ["main"],
  data() {
    var validatePass = (rule, value, callback) => {
      if (!value) {
        this.through = false;
        callback(new Error(this.$t("nullPwd")));
      } else {
        const rex = new RegExp(
          /^(?![^a-zA-Z]+$)(?!\D+$)(?![a-zA-Z0-9]+$).{8,}$/
        );
        const _check = rex.test(value);
        if (!_check) {
          this.through = false;
          callback(new Error(this.$t("passweak")));
        } else{
          this.$refs["resetPwd"].validateField('replayPwd', errorMessage => {
            if (!errorMessage) {
              this.through = true;
              callback()
            } else {
              this.through = false;
              callback()
            }
          })
      } 
      }
    };
    const validateReply = (rule, value, callback) => {
      if (!value) {
        this.through = false;
        callback(new Error(this.$t("")));
      } else {
        const rex = new RegExp(
          /^(?![^a-zA-Z]+$)(?!\D+$)(?![a-zA-Z0-9]+$).{8,}$/
        );
        const _check = rex.test(value);
        if (!_check) {
          this.through = false;
          callback(new Error(this.$t("passweak")));
        } else {
          if(this.formData.newPwd !== "" && value === this.formData.newPwd){
            this.through = true;
            callback();
          } else if (this.formData.newPwd !== "" && value !== this.formData.newPwd) {
            this.through = false;
            callback(new Error(this.$t("pwdNotSame")));
          } else {
            this.through = false;
            callback();
          }
        }
      }
    };
    return {
      formData: { newPwd: "", replayPwd: "" },
      rules: {
        newPwd: { validator: validatePass, trigger: "change" },
        replayPwd: { validator: validateReply, trigger: "change" }
      },
      through: false
    };
  },
  methods: {
    async handlerSubmitForm() {
      const params = {
        ...this.main.catchAccount,
        password: this.fnencrypt(this.formData.newPwd),
        providerId: this.$store.state.accountStore.secureInfo.providerId
      };
      const result = await setNewPwd(params);
      if (result && result.code === 200) {
        this.main.next();
      }
    }
  },
  render() {
    return (
      <div class="steps__item">
        <el-form
          props={{ model: this.formData, rules: this.rules, showMessage: false }}
          ref="resetPwd"
        >
          <el-form-item>
            <h2>ID：{this.main.account}</h2>
          </el-form-item>
          <el-form-item prop="newPwd">
            <el-input v-model={this.formData.newPwd} type="password">
              <i slot="prepend" class="el-icon--dun" />
              <span slot="append">{this.$t("theNew")}</span>
            </el-input>
          </el-form-item>
          <el-form-item prop="replayPwd">
            <el-input v-model={this.formData.replayPwd} type="password">
              <i slot="prepend" class="el-icon--dun" />
              <span slot="append">{this.$t("theNew")}</span>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type={this.through ? "primary" : "info"}
              onClick={ () => {
                if(this.through){
                  this.handlerSubmitForm()
                }
              }}
            >
              <i class="el-icon--step-next" />
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    );
  }
};
</script>
