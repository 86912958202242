<template>
  <div class="steps">
    <div class="steps__header">
      <span>{{ $t("forgetPwd") }}</span>
      <img
        class="servicer"
        @click="loading ? '' : customerService()"
        src="https://image.melinked.com/customer_group_head.png?imageView2"
        alt=""
        v-if="!loading"
      />
      <div v-else class="loading-box">
        <i class="el-icon-loading"></i>
      </div>
    </div>
    <div class="steps__content">
      <div class="steps__content--back">
        <i
          class="el-icon--prev blackBtn"
          @click="handlerPre"
          v-if="active && active != 4 && catchAccount.codeType != 3"
        ></i>
      </div>
      <component :is="`step-${steps[active]}`"></component>
    </div>
  </div>
</template>
<script>
import stepOne from "./steps/step-1";
import stepTwo from "./steps/step-2";
import stepThree from "./steps/step-3";
import stepFour from "./steps/step-4";
import stepFive from "./steps/step-5";
import stepSix from "./steps/step-6";

export default {
  name: "ForgetPwd",
  provide() {
    return {
      main: this,
    };
  },
  components: {
    stepOne,
    stepTwo,
    stepThree,
    stepFour,
    stepFive,
    stepSix,
  },
  data() {
    return {
      // 客服按钮状态
      loading: false,
      steps: {
        0: "one",
        1: "two",
        2: "three",
        3: "four",
        4: "five",
        5: "six",
      },
      active: 0,
      catchActive: 0,
      questionNum: 5,
      questionKeys: ["email", "phone", "question"],
      codeType: {
        phone: 1,
        email: 2,
        question: 3,
      },
      account: "",
      select: "",
      radios: [],
      catchAccount: {},
      // 是否点击过联系客服
      customerServiceFlag: false,
    };
  },
  watch: {
    "$store.state.accountStore.secureInfo": {
      handler(newly) {
        this.loadingData(newly);
      },
      deep: true,
    },
    active: {
      handler(newly, old) {
        this.catchActive = old;
      },
    },
  },
  async created() {
    this.$store.dispatch("commitMenuStatus", false);
    await this.loadingData(this.$store.state.accountStore.secureInfo);
  },
  methods: {
    // 创建游客身份并拉起客服
    async customerService() {
      if (!this.customerServiceFlag) {
        this.customerServiceFlag = true;
        this.loading = true;
        await this.handlerInitToursits();
        this.loading = false;
        this.jumpPage({
          name: "home",
        });
      }
    },
    loadingData(newly) {
      if (newly.secureEmail && !this._checkHasRadio("email")) {
        this.radios.push({
          label: this._handlerHiddlenAccount(newly.secureEmail), //this.$t("email"),
          value: "email",
        });
      }
      if (newly.securePhone && !this._checkHasRadio("phone")) {
        this.radios.push({
          label: this._handlerHiddlenAccount(newly.securePhone, "phone"),
          value: "phone",
        });
      }
      if (newly.hasSecureQuestion && !this._checkHasRadio("question")) {
        this.radios.push({
          label: this.$t("secureQuestion"),
          value: "question",
        });
      }
      if (this.radios.length !== 0) {
        this.select = this.radios[0].value;
      }
    },
    handlerCatchData(code, type, question) {
      this.catchAccount = {
        code,
        codeType: this.codeType[type],
      };
      if (question) this.catchAccount.question = question;
    },
    _handlerHiddlenAccount(val, type) {
      if (!val) return;
      const pre = val.slice(0, 6);
      let center = "";
      for (var i = 0; i <= val.length - 9; i++) {
        center += "*";
      }
      let end = val.slice(val.length - 3, val.length);
      let complete = pre + center + end;
      if (type === "phone") complete = "+" + complete;
      return complete;
    },
    _checkHasRadio(felid) {
      return this.radios.some((el) => {
        return el.value === felid && this.questionKeys.includes(el.value);
      });
    },
    next() {
      if (this.active++ > Object.keys(this.steps).length - 1) this.active = 0;
    },
    handlerPre() {
      if (this.radios.length === 1) this.active = 0;
      else if (this.active === this.questionNum) this.active = this.catchActive;
      else if (this.active-- <= 0) this.active = 0;
    },
    _resetData() {
      this.radios = [];
    },
  },
};
</script>
<style lang="stylus">
@import '~styles/utils/index';
.content{
  background #f5f5f5
}
.el-input__icon{
  font-size 20px
}
.loading-box {
  width 32px;
  height 32px;
  display flex;
  align-items center;
  justify-content: center;
}
+b(steps){
    padding 20px
    // flex 1
    width 60%
    margin 2rem auto
    min-height 68vh
    border 1px solid #e5e5e5;
    background #ffffff
    +e(header){
        display flex
        flex-flow row
        justify-content space-between
        border-bottom 1px solid #e5e5e5
        margin-bottom 2rem
        padding-bottom .9rem
        .servicer{
            width 32px
            height 32px
            object-fit  cover
        }
    }

    +e(content){
      display flex;
      flex-flow column
      +m(back){
        flex 1
        .blackBtn{
          font-size 18px
          cursor pointer
          margin 0 .5rem
          color #777777
        }
      }
    }
    +e(item){
      width 50%
      margin  0 auto
      +m(success){
        i{
          font-size 50px
        }
        span{
          font-size 14px
          cursor pointer
        }
        color #33cc66
        display flex
        flex-flow column
        align-items center
        justify-content center
      }
      .el-form-item{
        +e(error){
          position inherit
          line-height 18px
        }
        +e(content){
          .el-radio-group{
            display flex
            flex-flow column
            .el-radio{
              margin .9rem 0
            }
          }
          .el-button{
            width 100%
            padding 8px 20px
            &.el-button--info{
              background #DCDFE6
              border-color #DCDFE6
            }
            span {
              i{
                font-size 24px
              }
            }
          }
        }
      }
    }
}
</style>
